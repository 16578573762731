import './App.css';
import './landing.css';
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <div className="nav">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/estimate">Free Estimate</Link>
          </li>
        </ul>
      </div>
  );
}

export default Navbar;



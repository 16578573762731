import logo from './logo.svg';
import './App.css';
import './landing.css';
import { Link } from 'react-router-dom'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Services() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="content-section-services" id="servicesPage">
          <h1>Services</h1>
          <hr />
        <div className="content-grouping">
          <ul>
            <li>
              <img src="/img/tree.png" alt="" />
              <h2>Tree Removal</h2>
              <p>
              Say goodbye to unwanted or hazardous trees with our expert tree removal service. Whether it's a towering giant casting too much shade or a tree posing a threat to your property, our skilled arborists employ the latest techniques and equipment to safely and efficiently remove trees of all sizes. We prioritize safety and precision, ensuring a seamless process from evaluation to removal, leaving your property refreshed and secure.
              </p>
            </li>
            <li>
              <img src="/img/pruning-shears.png" alt="" />
              <h2>Trimming & Pruning</h2>
              <p>
              Give your trees the royal treatment with our meticulous trimming and pruning services. Our arborists are not just tree trimmers; they are horticultural artisans. By carefully shaping and sculpting your trees, we enhance their natural beauty, encourage healthy growth, and mitigate potential risks. Seasonal trimming ensures that your trees stand as proud monuments to nature's artistry, adding aesthetic value to your property.
              </p>
            </li>
            <li>
              <img src="/img/stump.png" alt="" />
              <h2>Stump Grinding</h2>
              <p>
              Unwanted stumps can be an eyesore and a hindrance to your landscaping plans. Our stump grinding service transforms these remnants into mere memories. Using state-of-the-art equipment, we grind down stumps below the surface, reclaiming valuable space and eliminating tripping hazards. What's left is a smooth, stump-free canvas ready for your next landscaping masterpiece.
              </p>
            </li>
            <li>
              <img src="/img/bonfire.png" alt="" />
              <h2>Firewood Processing & Delivery</h2>
              <p>
              Cozy up to the warmth of a crackling fire with our firewood processing and delivery service. We don't just provide firewood; we deliver quality and convenience to your doorstep. Our team expertly processes and prepares firewood, ensuring it's seasoned, dry, and ready to ignite those chilly evenings. With prompt and reliable delivery, we bring the comfort of a roaring fire right to your hearth.
              </p>
            </li>
          </ul>
          <div className="content-grouping-small">
            <h2>At Spring's Tree & Property Maintenance, our services go beyond the technicalities</h2>
            <p>
            We infuse each task with a passion for preserving the beauty of nature and elevating the aesthetics of your property. From tree removal to firewood delivery, we're dedicated to providing comprehensive solutions that enhance the health, safety, and allure of your outdoor space.
            </p>
          </div>
        </div>
        <h2>Ready to get started?</h2>
        <Link to="/esimate" className="mini-cta-btn">Get A Free Estimate</Link>
      </div> 
  );
}

export default Services;

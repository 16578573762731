import './App.css';
import './landing.css';

function EstimateForm() {
  return (
    <>
      <div className="content-section-bar shadow">
        <div className="content-grouping" id="estimate-group" >
            <h1>Ready for your free estimate?</h1>
        </div>
        <div className="content-grouping">
          <form class="estimate-form" name="estimateForm" method="POST" netlify>
          <input type="hidden" name="form-name" value="estimateForm" />
            <select name="services">
              <option name="TreeRemoval" value="TreeRemoval">Tree Removal</option>
              <option name="TrimmingPruning" value="Trimming">Trimming & Pruning</option>
              <option name="StumpGrinding" value="StumpGrinding">Stump Grinding</option>
              <option name="Firewood" value="Firewood">Firewood</option>
              <option name="MultipleServices" value="MultipleServicesNeeded">Multiple services</option>
            </select>
            <input type="text" name="First" placeholder='First' required/>
            <input type="text" name="Last" placeholder='Last' required/>
            <input type="tel" name="Phone" placeholder="Phone Number" required/>
            <input type="number" name="zipCode" placeholder="Your Zipcode"/>
            <textarea style={{padding: "10px"}} name="job-description" cols="30" rows="10" placeholder="Use this box to enter any additional information you think we should know"></textarea>
            <input type="submit" name="submitForm" value="Request My Estimate" />
          </form>
        </div>
      </div>
    </>    
  );
}

export default EstimateForm;

import logo from './logo.svg';
import './App.css';
import './landing.css';
import { Link } from 'react-router-dom'
import EstimateForm from './EstimateForm'
import Testimonials from './Testimonials';

function Landing() {
  return (
    <>
        <div className="call-to-action">
        <div className="cta-block-wrapper">
          <div className="cta-block vignette-bg">
            <h1 >Spring's Tree & Property Maintenance</h1>
          <img src="/img/STPM_stump_nobg.png" alt="" />
            <h1 style={{fontSize: "x-large"}}>The Ultimate Solution To Tree Care And Property Management</h1>
            <p>Property management is more than just hard work, it's the reflection of a bigger picture. When you hire Spring's Tree & Property Maintenance, you hire professionals who care. Please get in touch with us for a free estimate today and start the process of transforming your property into everything it can be. </p>
            <Link to="/estimate" className="mini-cta-btn">Request A Free Estimate</Link>
          </div>       
        </div>
      </div>

      <div className="content-section-services">
          <h1>Services</h1>
          <hr />
        <div className="content-grouping">
          <ul>
            <li>
              <img src="/img/tree.png" alt="" />
              <h2>Tree Removal</h2>
              <Link to="/services" className="mini-cta-btn">Learn More</Link>
            </li>
            <li>
              <img src="/img/pruning-shears.png" alt="" />
              <h2>Trimming & Pruning</h2>
              <Link to="/services" className="mini-cta-btn">Learn More</Link>
            </li>
            <li>
              <img src="/img/stump.png" alt="" />
              <h2>Stump Grinding</h2>
              <Link to="/services" className="mini-cta-btn">Learn More</Link>
            </li>
            <li>
              <img src="/img/bonfire.png" alt="" />
              <h2>Firewood Processing & Delivery</h2>
              <Link to="/services" className="mini-cta-btn">Learn More</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="content-section-bar shadow">
        <div className="content-grouping">
          <img src="/img/hatchet.jpg" alt="" className="content-section-image-left" />
          <p className="content-section-text-right">
            <h2 style={{padding: "10px", paddingLeft: "0"}}>Welcome to Spring's Tree & Property Maintenance</h2>
            Where the essence of nature meets the precision of professionalism. Our roots run deep in the heart of Clifton Park, NY and our branches extend to embrace the surrounding areas with a commitment to excellence.
            <p>
            <br/>
              Our journey began in 2016, driven by a passion for preserving the natural beauty that surrounds us. As stewards of the land, we understand that each tree, each blade of grass, and every corner of your property has its own story to tell. With a delicate touch and an eye for detail, we weave together a tapestry of greenery and tranquility that transforms your outdoor space into a sanctuary.
            </p>
          </p>

        </div>
      </div>

      <div className="content-section-bar" id="cta-bar">
        <div className="content-grouping vignette-bg">
          <p className="content-section-text-left">
            <h2 style={{padding: "10px", paddingLeft: "0"}}>It's not just business.</h2>
            As a company born from a love for nature and a commitment to community, we are not just in the business of tree and property maintenance; we are in the business of cultivating lasting relationships. When you choose Spring's Tree & Property Maintenance, you're not just investing in a service – you're investing in a partnership that nurtures the beauty of your outdoor space throughout the changing seasons.
          </p>
          <Link to="/about" className="mini-cta-btn">Learn More</Link>
        </div>
      </div>

      <div className="content-section-bar">
        <Testimonials />
      </div>

      <EstimateForm />
    </>    
  );
}

export default Landing;

import logo from './logo.svg';
import './App.css';
import './landing.css';
import Navbar from './Navbar'
import Landing from './Landing'
import Services from './Services';
import About from './About';
import Estimate from './Estimate';
import Footer from './Footer';
import {Route, Routes} from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />   
      <div className="full-screen-container">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/estimate" element={<Estimate />} />
        </Routes>
      </div>
      <Footer />
    </>   
  );
}

export default App;

import './App.css';
import './landing.css';
import EstimateForm from './EstimateForm'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Estimate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="estimate-background-wrapper">
        <div className="blur-backdrop">
          <EstimateForm/>
        </div>
      </div>
    </>    
  );
}

export default Estimate;

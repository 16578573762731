import logo from './logo.svg';
import './App.css';
import './landing.css';
import ImageSlider from './ImageSlider'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function About() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
        <div className="about-wrapper">
          <ImageSlider></ImageSlider>  
        </div>
    </>  
  );
}

export default About;

import './App.css';
import './landing.css';
import { useState } from "react"

const slideImages = [
    {
        url: '/img/john_in_action.jpg',
        title: 'Meet John Spring',
        text: "John embarked on this journey in 2016, establishing a company that would not only provide top-tier tree and property services, but also cultivate a deep connection between himself and the people living in the Capital Region. John is more than a businessman; he is a steward of the environment, committed to preserving the beauty of landscapes in Clifton Park, NY, and the rest of the Capital District. His journey into the world of tree and property care is marked by a genuine love for the outdoors and a desire to share that love with the community."
    },
    {
        url: '/img/bucket_truck.jpg',
        title: 'No Job Is Too Tough',
        text: "At Spring's Tree & Property Maintenance, we thrive on challenges because we believe that no job is too tough for our skilled team of arborists and property care experts. From towering trees to stubborn stumps, we tackle each task with unwavering determination and a commitment to excellence."
    },
    {
        url: '/img/saws_in_yard.jpg',
        title: 'The Right Tools For The Job',
        text: "We believe in equipping our team with the best tools because we understand that precision requires the right instruments. We take pride in our commitment to excellence, and that commitment extends to the tools we use. When it comes to getting the job done right, we rely on the power and reliability of industry-leading brands, such as Stihl and Kubota."
    },
];

function ImageSlider() {
    const [imageIndex, setImageIndex] = useState(0);

    function showNextImage() {
        setImageIndex(index => {
            if(index === slideImages.length - 1) return 0;
            return index + 1
        })
    }
    
    function showPrevImage() {
        setImageIndex(index => {
            if(index === 0) return slideImages.length - 1;
            return index - 1
        })
    }
    
  return (
    <>
      <div className="slide-container" style={{width: "100%", height: "100vh", aspectRatio: "10/5", margin: "0 auto", position: "relative"}}>
        <div style={{width: "100%", height: "100%", display: "flex", overflow: "hidden"}}>
            {slideImages.map( image => 
                <img key={image.url} src={image.url} className="img-slider-img" style={{translate: `${-100*imageIndex}%`}} loading="lazy" />
            )}
        </div>
        <button onClick={showPrevImage} className="img-slider-navBtn" style={{left: "0"}}> <img src="/img/back_white.png" alt="" style={{maxWidth: "64px", color: "white"}}/> </button>
        <button onClick={showNextImage} className="img-slider-navBtn" style={{right: "0"}}> <img src="/img/next_white.png" alt="" style={{maxWidth: "64px"}} /> </button>
        <p className="vignette-bg">
            <h2>{slideImages[imageIndex].title}</h2>
            {slideImages[imageIndex].text}
        </p>
      </div>
    </>    
  );
}

export default ImageSlider;

import './App.css';
import './landing.css';
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-block">
          <h1>Service Areas</h1>
          <div className="service-area-wrapper" style={{display: "flex"}}>
          <ul style={{padding: "1em", paddingLeft: "0"}}>
              <li>Schenectady, NY</li>
              <li>Watervliet, NY</li>
              <li>Rotterdam, NY</li>
              <li>Amsterdam, NY</li>
              <li>Saratoga Springs, NY</li>
              <li>Gloversville, NY</li>
            </ul>
            <ul style={{padding: "1em", paddingLeft: "0"}}>
              <li>Clifton Park, NY</li>
              <li>Halfmoon, NY</li>
              <li>Albany, NY</li>
              <li>Cohoes, NY</li>
              <li>Troy, NY</li>
            </ul>
            
          </div>
        </div>
        <div className="footer-block">
          <h1>Contact Us</h1>
          <a href="tel:518-852-1774"><img src="/img/phone-call.png" style={{maxWidth: "32px", justifyContent: "center", alignContent: "center", verticalAlign: "middle", padding: "4px"}} />(518) 852-1774</a>
          <a href="mailto:springspropertymaintenance@gmail.com"><img src="/img/email.png" style={{maxWidth: "32px", justifyContent: "center", alignContent: "center", verticalAlign: "middle", padding: "4px"}} />springspropertymaintenance@gmail.com</a>
        </div>
        <div className="footer-block">
          <h1>Quick Links</h1>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/estimate">Free Estimate</Link>
          </li>
        </ul>
        </div>
      </div>
    </>    
  );
}

export default Footer;

import './App.css';
import './landing.css';
import { useState } from "react"

const testimonials = [
    {
        author: 'Karen Obzud',
        text: '"I had a good sized tree close to a deck and a fence that I wanted removed. it was removed and stump ground with the area being cleaned up perfectly and at what I felt was an extremely fair price. it was a pleasure working with a company that was here when they said they would be and responded quickly to any message I left. I would highly recommend this company."'
    },
    {
        author: 'Steve Walsh',
        text: '"John cleaned up a fallen oak in my yard and we then brought him back to take down 6 more trees. Excellent communication and attention to detail. He and his crew take time to clean the surrounding yard and we are very happy with how everything looks. We will be calling John for any future projects and highly recommend."'
    },
    {
        author: 'Jorge L. Rivera Mojica',
        text: '"I recommend them 100% super responsible and super careful with everything and super decent with what it charges. Next year I hire them again."'
    },
];

function Testimonials() {
    function deviceLinkRedirect() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            // Do mobile browser commands
            window.location = "fb://page/100537058167876";
             }else{
                 //Do desktop browser commands
                 window.location = "https://www.facebook.com/johnTspring2/reviews";
             }
    }
  return (
    <>
    <div>
        <h1 style={{textAlign: "center", paddingBottom: "10px"}}>Testimonials</h1>
        <hr className="fancyHR"/>
            <div className="testimonial-wrapper">
                {testimonials.map( (item, index) => 
                    <div key={index} className="testimonial-card" id={`card-${index}`}>
                        <h3>{item.author}</h3>
                        <p>{item.text}</p>
                    </div>
                )}
            </div>
        <button onClick={deviceLinkRedirect} style={{border: "none", padding: "15px", cursor: "pointer"}} className="mini-cta-btn">See More On Facebook</button>
    </div>
    </>    
  );
}

export default Testimonials;
